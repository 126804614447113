html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}

code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}

code[class*='language-'] {
  padding: 0;
}

/* a {
  color: white;
} */

.cursor {
  cursor: pointer !important
}

.ql-editor {
  height: "100%" !important;
  overflow-y: visible !important;
  min-height: 500px !important;
}

.ql-container {
  height: auto !important;
  border: none !important;
}

.ql-toolbar {
  /* border: none !important;
  border-bottom: 1px solid gray !important; */
  background: #eaecec;
  border: 1px solid #ccc;
}

.standard-button {
  height: 32px !important;
  width: 32px !important;
}

/* Button */
/* .ant-btn-icon-only.ant-btn-sm, .ant-btn.ant-btn-icon-only {
  width: 44px !important;
  height: 44px !important;
} */

.animation-zoom {
  -webkit-transition: width 1s ease-in-out, padding-left 1s ease-in-out, padding-right 1s ease-in-out;
  -moz-transition: width 1s ease-in-out, padding-left 1s ease-in-out, padding-right 1s ease-in-out;
  -o-transition: width 1s ease-in-out, padding-left 1s ease-in-out, padding-right 1s ease-in-out;
  transition: width 1s ease-in-out, padding-left 1s ease-in-out, padding-right 1s ease-in-out;
  transition-delay: 3s;
}


.grecaptcha-badge { 
  bottom:12px !important;
  visibility: hidden;
}

/* Zalo */
@keyframes zoom {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes lucidgenzalo {
  0% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(25deg);
  }
}
.jscroll-to-top {
  bottom: 100px;
}
.fcta-zalo-ben-trong-nut svg path {
  fill: #fff;
}
.fcta-zalo-vi-tri-nut {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 999;
}
.fcta-zalo-nen-nut,
div.fcta-zalo-mess {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06), 0 2px 32px rgba(0, 0, 0, 0.16);
}
.fcta-zalo-nen-nut {
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  background: #0068ff;
  border-radius: 50%;
  position: relative;
}
.fcta-zalo-nen-nut::after,
.fcta-zalo-nen-nut::before {
  content: '';
  position: absolute;
  border: 1px solid #0068ff;
  background: #0068ff80;
  z-index: -1;
  left: -20px;
  right: -20px;
  top: -20px;
  bottom: -20px;
  border-radius: 50%;
  animation: zoom 1.9s linear infinite;
}
.fcta-zalo-nen-nut::after {
  animation-delay: 0.4s;
}
.fcta-zalo-ben-trong-nut,
.fcta-zalo-ben-trong-nut i {
  transition: all 1s;
}
.fcta-zalo-ben-trong-nut {
  position: absolute;
  text-align: center;
  width: 60%;
  height: 60%;
  left: 10px;
  bottom: 24px;
  line-height: 70px;
  font-size: 25px;
  opacity: 1;
}
.fcta-zalo-ben-trong-nut i {
  animation: lucidgenzalo 1s linear infinite;
}
.fcta-zalo-nen-nut:hover .fcta-zalo-ben-trong-nut,
.fcta-zalo-text {
  opacity: 0;
}
.fcta-zalo-nen-nut:hover i {
  transform: scale(0.5);
  transition: all 0.5s ease-in;
}
.fcta-zalo-text a {
  text-decoration: none;
  color: #fff;
}
.fcta-zalo-text {
  position: absolute;
  top: 6px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  transform: scaleX(-1);
  transition: all 0.5s;
  line-height: 1.5;
}
.fcta-zalo-nen-nut:hover .fcta-zalo-text {
  transform: scaleX(1);
  opacity: 1;
}
div.fcta-zalo-mess {
  position: fixed;
  bottom: 22px;
  right: 42px;
  z-index: 99;
  background: #fff;
  padding: 7px 25px 7px 7px;
  color: #0068ff;
  border-radius: 50px 0 0 50px;
  font-weight: 700;
  font-size: 15px;
}

.fcta-zalo-mess span {
  color: #0068ff !important;
}

span#fcta-zalo-tracking {
  font-family: Roboto;
  line-height: 1.5;
}

.fcta-zalo-text {
  font-family: Roboto;
}

.class-name-can-edit:hover, .class-name-can-click:hover {
  cursor: pointer;
}

.speak-html-content p {
  color:"black";
  margin: 0;
  padding: 0;
}

.header-grid {
  /* border: none !important;
  border-bottom: 1px solid gray !important; */
  background: black;
  color:white
}

.font-bold {
  font-weight: bold;
}

.class-name-ant-icon {
  font-size: 24px !important;
}

.no-underline { 
  text-decoration: none; 
} 
